.transparent-btn-md {
  background-color: transparent;
  border: none;
  padding: 12px 18px;
  position: relative;
  font-size: 1rem;
  color: #4a4d59;
  cursor: pointer;
}

.transparent-btn-md img {
  vertical-align: middle;
}

.transparent-btn-md.pl-0 {
  padding-left: 0;
}

.transparent-btn-md.p-0 {
  padding: 0;
}

.OrderDetails {
  font-family: "Poppins", sans-serif;
}

.OrderDetails:focus-visible {
  outline: none;
}
