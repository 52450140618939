.textfield-v1 {
  input {
    height: 1.875rem;
    padding: 5px 14px;
  }

  fieldset {
    border: none;
  }
}

.textfield-v1 input::placeholder {
  color: rgba(79, 86, 113);
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  /* 200% */
}

.textfield-v1 input:-webkit-autofill {
  border-radius: 1.5rem;
  -webkit-background-clip: text;
}

.vInput {

  margin-bottom: 0.5rem;

  input {
    border: none;
    height: 30px;
    padding-left: 1.5rem;
    font-size: 13px;
    border-radius: 1.5rem;
    border: 1px solid #E5E5E5;
  }

  &::before,
  &::after {
    display: none;
  }
}