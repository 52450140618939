.signin-main-wrapper {
  .signin-box-left {
    max-width: 688px;
    margin: auto;

    .signin-form-box {
      max-width: 373px;
      margin-left: auto;
      margin-right: auto;
    }
    .signin-submit-button {
      background-color: #f7a614;
      color: #000;
      box-shadow: none;
      border-radius: 6px;
      // min-height: 50px;
      padding: 4px 0;
      border: 1px solid #f7a614;
    }
    .signin-textfield {
      background-color: rgba(248, 200, 200, 1);
      border-radius: 5px;
      border: 1px solid rgba(81, 81, 81, 1);
      input {
        height: 1.875rem;
        padding: 2px 14px;
      }

      fieldset {
        border: none;
      }
    }
  }
  .signin-box-right {
    min-height: 100vh;
    background-image: linear-gradient(
      174.2deg,
      rgba(255, 239, 238, 1) 7.1%,
      rgba(255, 244, 223, 1) 67.4%
    );
  }
  .signin-logo-box {
    max-width: 100%;
    margin-bottom: 6rem;
    img {
      max-width: 100%;
    }
  }
  .lottie-box-outer {
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
