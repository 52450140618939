.mainSidebar {
  &.collapsed {
    .MuiDrawer-paper {
      width: 100px;
    }
    .inputSearchV1 {
      width: 67px;

      .icon {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      input::placeholder {
        color: transparent;
      }
    }

    .MenuList {
      & > .MuiTypography-body1 {
        margin-left: 0;
        text-align: center;
      }
      & > li {
        display: block;
        text-align: center;
      
        // padding: 11.48px 1rem 11.48px 1rem;
      }
    }

    .squareBtnV1 {
      transform: rotate(180deg);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .subMenulist{
    padding-top: 10px;
    padding-bottom: 10px;
    gap: .55rem;
  }
}

.collapsed .subMenulist{
  text-align: center;
  display: block;
}