@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400;500;600;700&display=swap');

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Light-BETA.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./fonts/Inter-ExtraLight-BETA.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./fonts/Inter-Thin-BETA.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Industry Inc";
  src: local("Industry Inc Base"), local("Industry-Inc-Base"),
    url("./fonts/IndustryInc/IndustryInc-Base.woff2") format("woff2"),
    url("./fonts/IndustryInc/IndustryInc-Base.woff") format("woff"),
    url("./fonts/IndustryInc/IndustryInc-Base.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0 auto;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-white {
  border-radius: 24px;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.15);
  color: #5E768D;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  border: none;
  cursor: pointer;
  transition: all 100ms ease;
}

.btn-white:hover {
  background-color: rgba(250, 250, 250);
}


.btn-white:disabled {
  background: #dfdcdc;
  cursor: not-allowed;
}

.btn-white.customized {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.text-info-v1 {
  color: rgba(59, 65, 85, 1);
  color: #3b4155;
}

.text-info-v2 {
  color: rgb(79, 86, 113);
  color: #4f5671;
}

.text-white {
  color: #ffffff;
}

.text-gradient-v1 {
  background: linear-gradient(90deg, rgba(223, 145, 29, 0.75) 0%, #b3201d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-success {
  color: #009d2c;
}

.fsr-21 {
  font-size: 1.313rem;
}

.fw-600 {
  font-weight: 600;
}

.pl-0 {
  padding-left: 0;
}

.mh-430 {
  min-height: 430px;
}

.image-InBox {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inputSearchV1 {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.inputSearchV1>input {
  border-radius: 25px;
  background: #FFEFE4;
  padding: 13.2px 13.2px 13.2px 34px;
  border: none;
  outline: none;
  font-size: 12px;
  width: 100%;
  max-width: 170px;
}

.inputSearchV1 .icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.inputSearchV1>input::placeholder {
  color: rgba(94, 118, 141, 0.50);
}



.vertical-dividerGradient1 {
  min-height: 200px;
  width: 4px;
  height: calc(90vh - 140px);
  background: linear-gradient(180deg, #b3201d 0%, rgba(223, 145, 29, 0) 100%);
}

.toolbar img {
  max-height: 50px;
  width: auto;
}

.toolbar>button {
  position: relative;
}

.collapsed .toolbar>button {
  left: -8px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.MenuList {
  max-height: calc(100vh - 212px);
  overflow: auto;
}

.MenuList::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.MenuList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.MenuList::-webkit-scrollbar-thumb {
  background: #f0d0bb;
  border-radius: 10px;
}

/* Handle on hover */
.MenuList::-webkit-scrollbar-thumb:hover {
  background: #ecb189;
  cursor: pointer;
}

.MenuList::-webkit-scrollbar-thumb:active {
  background: #e99c69;
}


.MenuList>li {
  /* .MenuList>li .listItemCustomized { */
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 60px;
  background: none;
  border: 1px solid transparent;
  transition: all 0.5s ease-in-out;
  gap: 0.5rem;
  text-transform: uppercase;
  background-color: transparent;
}

.MenuList>li img,
.MenuList>li svg {
  display: block;
  min-height:20px;
}

.MenuList>li:hover {
  border-color: #FFEFE4;
  background-color: transparent;
}


.MenuList>li.Mui-selected {
  background: #FFEFE4;
  color: rgba(205, 77, 75, 1);
}

.MenuList>li.Mui-selected:hover {
  background: #FFEFE4;
}



/* .MenuList>li::after {
  content: "";
  background: none;
} */

ul.MenuList li * {
  font-family: 'Reem Kufi',
    sans-serif;
}

.MenuList>li span {
  font-size: 12px;
  font-weight: 700;
 
}
.MenuList>li>span:first-child,
.subMenulist>span:first-child{
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0 auto;

 
} 

.MenuList>li>div {
  color: currentColor;
  text-align: left;
}

.MenuList>li svg {
  color: currentColor;
  font-size: 23px;
  display: inline-block;
  max-height: 18px;
}

.MenuList>li.Mui-selected::after {
  width: 100%;
}


.lineChart {
  max-height: 350px;
}

.listV1 {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.listV1 li p {
  margin-top: 0;
  margin-bottom: 4px;
}



/***** placeholder css below  ******/

.loads {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeload;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eee;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1200px 104px;
  position: relative;
}

@keyframes placeload {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

/********** here placeholder css finishes  ********/



.gridResto {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  margin-top: 0 !important;
}