::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, rgba(223, 145, 29, 0.75) 0%, #b3201d 100%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(90deg, rgba(223, 145, 29, 0.75) 0%, #b3201d 100%);
  cursor: pointer;
}

div:where(.swal2-container).swal2-backdrop-show {
  z-index: 1301;
}

button {
  cursor: pointer;
  font-family: inherit;
}

.font-Reem-Kufi {
  font-family: "Reem Kufi", sans-serif !important;
}

.outlineBtnV1 {
  border-radius: 24px;
  border: 1px solid #4f5671;
  background: #fff;
  padding: 8px;
  color: rgba(79, 86, 113, 0.5);
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
  width: 100%;

  &:hover {
    background-color: #ece9e9;
  }
}

.squareBtnV1 {
  border-radius: 8px;
  background: #ffefe4;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 200ms ease;
}

.circleBtnV1 {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cd4d4b;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  background-color: transparent;

  &.selected {
    background-color: #cd4d4b;
    border-color: #cd4d4b;
    color: white;
  }
}

.btn-success {
  border-radius: 24px;
  border: 1px solid #00b976;
  background: #00b976;
  padding: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
}

.btn-danger {
  border-radius: 24px;
  border: 1px solid #ee2d26;
  background: #ee2d26;
  padding: 8px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
}

.btn-danger:hover {
  border: 1px solid #e9433e;
  background: #e9433e;
}

.btn-danger-outline {
  border-radius: 24px;
  border: 1px solid #ee2d26;
  background: transparent;
  padding: 8px;
  color: #ee2d26;
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
  transition: all 200ms ease;
}

.btn-danger-outline:hover {
  background: #ee2d26;
  color: white;
}

.absolutePositionedTopRight {
  position: absolute !important;
  top: 22px;
  right: 16px;
  padding: 0;
  z-index: 1;
}

.inputInvisible {
  position: absolute;
  inset: 0;
  opacity: 0;
}

.inputV1 {
  border-radius: 24px;
  border: 1px solid #e5e5e5;
  padding: 11.5px 16px 11.5px 16px;
  outline: none;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
  font-family: "Poppins", sans-serif;
  color: rgba(79, 86, 113);
}

.inputV1::placeholder {
  color: rgba(79, 86, 113, 0.5);
}

.FormControl {
  .select-v1 {
    position: relative;
    background-color: transparent;
    min-width: 156px;

    .MuiSelect-select {
      border-radius: 36px;
      border: 1px solid #ffefe4;
      background: #ffefe4;
      padding: 5.5px 2.5rem 5.5px 1.5rem;
      padding-right: 50px !important;
      font-size: 13px;
      font-weight: 600;
      color: #5e768d;
      // font-family: "Reem Kufi";
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.24px;
      text-transform: uppercase;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiSvgIcon-root {
      display: none;
    }

    &::after {
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><mask id="mask0_1831_3914" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20"><rect x="20" width="20" height="20" transform="rotate(90 20 0)" fill="%23D9D9D9"/></mask><g mask="url(%23mask0_1831_3914)"><path d="M10 10.5001L13.8333 6.66675L15 7.83341L10 12.8334L5 7.83341L6.16667 6.66675L10 10.5001Z" fill="%234F5671"/></g></svg>');
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5rem;
      line-height: 0;
      pointer-events: none;
    }
  }
}

label.inputLabel1 {
  padding-left: 6px;
  padding-right: 6px;
  background-color: white;
  color: #4f5671;
  font-size: 12px;
  transform: translate(14px, 11px) scale(1);
  font-weight: 500;
}

label.inputLabel1.css-dwr5vv-MuiFormLabel-root-MuiInputLabel-root,
label.inputLabel1.Mui-focused,
label.inputLabel1.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.labelled-Dropdown1 {
  .MuiFormControl-root {
    &>label {
      transform: translate(14px, 8px) scale(1);
      color: #4f5671;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      /* 200% */

      &.css-dwr5vv-MuiFormLabel-root-MuiInputLabel-root,
      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(14px, -8px) scale(0.75);
        background-color: white;
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}

// label.inputLabel2 {
//   transform: translate(14px, 11px) scale(1);
// }

// label.inputLabel2.Mui-focused {
//   transform: translate(14px, -9px) scale(0.75);
// }
.selectV2:not(.w-full) {
  max-width: 150px;
}

div.selectV2 {
  border-radius: 24px;
  color: #4f5671;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;

  .MuiSelect-select {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }

  fieldset {
    border-color: #e5e5e5;
  }

  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #e5e5e5;
    }
  }
}

div.selectV2.addedStyling {
  max-width: none;

  fieldset {
    top: 0;

    legend {
      display: none;
    }
  }
}

div.selectV2.customized {
  max-width: none;
  text-align: center;
}

div.selectV2.customized .MuiSvgIcon-root {
  right: 30%;
}

div.selectV3 {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.15);
  border-color: transparent;
  color: #5e768d;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  .MuiSelect-select {
    padding-top: 11.5px;
    padding-bottom: 11.5px;
  }

  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }

  fieldset {
    border-color: transparent;
  }
}

div.selectV4 {
  border-radius: 24px;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.15);
  color: #5e768d;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  background-color: white;

  .MuiSelect-select {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }

  fieldset {
    border-color: transparent;
  }
}

.MuiPopover-root.MuiMenu-root ul li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-root {
  color: #4f5671;
  font-size: 12px;
  font-weight: 500;
}

// .MuiPopover-root.MuiMenu-root ul {
//   border: 3px solid red;
// }
.radioV1Wrapper {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.15);
  display: flex;
  color: #cd4d4b;
  // font-family: "Reem Kufi";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  >.radioV1 {
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      inset: 0;
      cursor: pointer;
    }

    input+label {
      padding: 11px 40px;
      background-color: white;
      display: inline-block;
      // border-radius: 24px;
    }

    input:checked+label {
      background: #cd4d4b;
      color: white;
      border-radius: 24px;
    }

    &:first-child {
      input+label {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
        // border-radius: 24px 0 0 24px;
      }
    }

    &:last-child {
      input+label {
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    }
  }
}

.lh-1 {
  line-height: 1;
}

.mx-10px {
  margin-left: 10px;
  margin-right: 10px;
}

.ms-auto {
  margin-left: auto;
}

.box-v1 {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  background: rgba(236, 237, 245, 1);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.813rem;
  position: relative;
}

.table-v1 {
  th {
    font-size: 16px;
    font-weight: 600;
  }

  td,
  th {
    border: none;
    padding: 12px;
  }

  .css-1j0tll6-MuiTableRow-root:nth-of-type(odd) {
    background-color: rgba(235, 237, 245, 0.2);
  }

  .css-95wtso-MuiTableCell-root {
    border: none;
  }
}

.tavleV1 {
  border-bottom: 1px solid #e5e5e5;
  border-radius: 1.5rem;

  thead {
    tr {
      th {
        color: white;
        text-transform: uppercase;
        font-weight: 500;
        padding: 8.312px 1.5rem;
        font-family: "Reem Kufi", sans-serif;
      }
    }
  }

  tbody {
    border-bottom: 1px solid #e5e5e5;

    tr.MuiTableRow-root {
      background-color: white;

      td {
        font-size: 14px;
        padding: 11px 1.5rem;
        color: #4f5671;

        &:nth-child(3) {
          color: rgba(79, 86, 113, 0.5);
        }

        &:not(:first-child) {
          border-left: 1px solid #e5e5e5;
        }
      }
    }
  }
}

.tavleV1.customized {
  tbody {
    tr {
      td {
        &:nth-child(3) {
          color: #4f5671;
        }
      }
    }
  }
}

.tavleV1.customized2 {
  border-radius: 0;

  tbody {
    tr {
      td:first-child {
        border-left: 1px solid #e5e5e5;
        max-width: 200px;
        width: 200px;
      }

      td:last-child {
        border-right: 1px solid #e5e5e5;
      }
    }
  }
}

.circleButtonV1 {
  border-radius: 50%;
  padding: 6px;
  border: none;
  color: white;
  background: linear-gradient(90deg, rgba(223, 145, 29, 0.75) 0%, #b3201d 100%);
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-middle {
  vertical-align: middle;
}

.badge-gradient-v1 {
  border-radius: 30px;
  background: linear-gradient(90deg,
      rgba(179, 32, 29, 0.8) 0%,
      rgba(223, 145, 29, 0) 100%);
  padding: 6px 20px;
}

.cardV3 {
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  padding: 1.5rem;
}

.cardV4 {
  border-radius: 16px;
  border: 1px solid #FFEFE4;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.05);
}

.listV2 {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;

  >li {
    border-radius: 8px;
    background: #f5f5f5;
    padding: 10px;
    color: #485970;
    // font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 200%;
  }
}

.listV3 {
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: #7d90a3;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
  border-radius: 8px 8px 0px 0px;
  background: #f8f8f8;
  grid-template-rows: auto auto auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: space-between;
  align-content: space-between;
  gap: 1rem;

  img {
    vertical-align: middle;
  }

  &>li {
    display: block;
    gap: 0.75rem;
    max-width: 130px;
    cursor: pointer;

    &:nth-child(even) {
      min-width: 130px;
      margin-left: auto;
    }
  }
}

.CollapsibleList.opened {
  border-radius: 16px;
  background: #ffefe4;
  color: #c0201e;

  &:hover {
    background: #ffefe4;
  }
}

ul.menulistV1 {
  color: rgba(94, 118, 141, 0.6);
  padding-left: 35px;
  padding-top: 0;
  padding-bottom: 10px;

  li {
    padding-left: 0;
    position: relative;
    display: list-item;
    list-style-type: disc;
    background-color: transparent !important;
    &:hover {
      color: #c0201e;
    }
    &.Mui-selected {
      color: #c0201e;
    }

    &::marker {
      font-size: 12px;
    }

    span {
      font-weight: 400;
      text-transform: none;
      text-align: left;
      font-family: "Poppins", sans-serif;
    }

    &::before {
      content: none;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
  }
}

div.textfield-v2 {
  .MuiInputBase-root {
    border-radius: 24px;
    color: initial;
    font-size: 12px;
    font-weight: 500;
    line-height: 200%;

    input {
      padding-top: 11.4px;
      padding-bottom: 11.4px;
      color: rgb(79, 86, 113);

      &::placeholder {
        color: rgba(79, 86, 113);
        font-size: 12px;
        font-weight: 500;
        line-height: 200%;
      }
    }

    &:hover {
      fieldset {
        border-color: #e5e5e5;
      }
    }

    fieldset {
      border-color: #e5e5e5;
    }
  }
}

.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  border-radius: 1rem;
}

.imgV1 {
  width: 360px;
  height: 112px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.radioGroupV1 {
  gap: 1.5rem;
}

.radioGroupV1 span {
  font-size: 12px;
  color: #4f5671;
  font-weight: 500;
}

.uploadBoxV1 {
  border-radius: 8px;
  border: 1px dashed #e5e5e5;
  background: #fff;
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  font-size: 12px;
}

.uploadBoxV1 p {
  margin: 0;
  color: #4f5671;
}

.uploadBoxV1>span {
  color: #9d9d9d;
  line-height: 1;
}

.btn-link-v1 {
  color: #03f;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  display: inline;
  padding: 0;
}

.text-v1 {
  color: #4f5671;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.notification-body {
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 15px;
  }
}

div.grid-child-v1 {
  @media screen and (min-width: 1700px) {
    max-width: 20%;
    flex-basis: 20%;
  }

  @media screen and (min-width: 2000px) {
    max-width: 16.66%;
    flex-basis: 16.66%;
  }
}

.list-v1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
  padding-left: 1rem;
}

.list-v1>li {
  border: 1px solid #e5e5e5;
  padding: 4px 12px;
  border-radius: 4px;
}

.list-v1>li .count {
  font-weight: 600;
}

div.tbgv1 {
  background-color: white;
  padding: 0.5rem;
  border-radius: 24px;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.15);
  gap: 1rem;

  &>button {
    padding: 0.226rem 1rem;
    border-radius: 1.5rem !important;
    border-color: transparent;
    font-size: 12px;
    color: #5e768d;
    font-weight: 500;

    &.Mui-selected {
      background-color: #c0201e !important;
      color: white;
      opacity: 0.8;
    }
  }
}

div.tbgv1.customzied {
  box-shadow: none;

  &>button.MuiButtonBase-root {
    border: 1px solid #ffefe4;

    &.Mui-selected {
      border-color: #c0201e;
    }
  }
}

// .tbgv1{
//     & > button.Mui-selected{
//         background: rgba(0, 185, 118, 1);
//         color:#fff;

//         &:hover{
//         background: rgba(0, 185, 118, 0.7);
//         }
//     }
// }

.FormControl .MuiSelect-select {
  padding: 10px 14px;
  font-size: 14px;
  background: #fff;
}

.rightArrow {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 2px;
  margin: 4px 10px;
  border-radius: 9999px;
  background-color: #cd4d4b;
}

.rightArrow::before,
.rightArrow::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  width: 10px;
  height: 2px;
  border-radius: 9999px;
  background-color: #cd4d4b;
  transform-origin: calc(100% - 0px) 50%;
}

.rightArrow::before {
  transform: rotate(45deg);
}

.rightArrow::after {
  transform: rotate(-45deg);
}

.bspace24 {
  margin-bottom: 1.5rem;
}

button.variantOne {
  border-radius: 24px;
  box-shadow: none !important;

  padding-left: 1.6rem;
  padding-right: 1.6rem;
  text-transform: capitalize;
}

button.variantOne.MuiButton-contained,
button.variantOne.MuiButton-contained.MuiButton-colorPrimary {
  color: #fff;
}
.creatableSelectStyle .css-13cymwt-control,
.creatableSelectStyle .css-t3ipsp-control{
  border-radius: 70px;
  min-height: 40px;
}

.creatableSelectStyle .css-t3ipsp-control{
  border-color: #C0201E;  
  box-shadow: 0 0 0 1px #C0201E;
}
.creatableSelectStyle .css-13cymwt-control .css-1p3m7a8-multiValue{
  font-size: 14px;
}
.creatableSelectStyle .css-1jqq78o-placeholder{
  font-size: 14px;
}
.creatableSelectStyle .css-1nmdiq5-menu{
  font-size: 14px;
}

body .creatableSelectChipStyle{
  gap:8px;
}
.creatableSelectChipStyle>div{
  border-radius: 4px;
  background: #F5F5F5;
  height: 24px;
  font-size: 11px;

}
.creatableSelectChipStyle.whiteChip>div{  background: #fff;}
body .creatableSelectChipStyle>div svg{
  color: #EE2D26!important;
  width: 15px;
  height: 15px;
}
.customerCard .cardV1>div>div{
  gap: 1rem;
}

.btnStyle1{
  display: flex;
  align-items: center;
  gap: 9px;
  text-transform: capitalize;
  font-weight: 500;
}


.tableStyle2 table {
  border-collapse: separate;
  border-spacing: 0 16px;
  margin-top: -10px;
}
.tableStyle2 tr {
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px rgba(223, 207, 195, 0.25);

}
.tableStyle2 tr td{
  border:none;
  background:#FFF!important;  
  text-align:left;
  height:64px;
}
.tableStyle2 tr td:first-child{
  border-top-left-radius:16px;
  border-bottom-left-radius:16px;
}
.tableStyle2 tr td:last-child{
  border-top-right-radius:16px;
  border-bottom-right-radius:16px;
}
.tableStyle2 .cellChip{
  gap:8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tableStyle2 .cellChip>div{
  border-radius: 4px;
  background: #F5F5F5;
  height: 25px;
  color:#485970;
  font-size: 10px;
}
.TextFieldStyle1 .MuiInputBase-formControl{
  border-radius:70px;
}
.TextFieldStyle1 label{ font-size:14px;}
.modal-content{
  outline:none;
}
.chipStyle1>div{
  border-radius: 4px;
  border: 1px solid rgba(205, 77, 75, 0.30);  
  background: #FFF;
  font-size: 12px;
}
.chipStyle1>div svg{
  color:#EE2D26!important;
  font-size: 16px!important;
}