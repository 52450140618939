.cardV1 {
  border-radius: 16px;
  // opacity: 0.8;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(223, 207, 195, 0.25);
  padding: 1.5rem;
  height: 100%;

  svg,
  span {
    vertical-align: middle;
  }

  & > h3 {
    margin-top: 0;
    color: #5e768d;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;
    text-transform: uppercase;
  }

  .iconSpan {
    margin-left: 0.5rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    color: #5e768d;
    font-size: 14px;
    line-height: 142.857%;
    margin-bottom: 0;

    > li {
      display: flex;
      align-items: center;

      > span {
        &:first-child {
          margin-right: 4px;
        }
      }
    }
    > li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.badge-box-v1 {
  display: flex;
  width: 46px;
  height: 46px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #ffefe4;
  color: #cd4d4b;
  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.text-green {
  color: #cd4d4b;
}

.text-red {
  color: #009d2c;
}
