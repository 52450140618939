.paginationV1 {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-radius: 64px;
  border: 1px solid #e5e5e5;
  background: #fff;

  button {
    font-size: 12px;
  }

  ul {
    li:not(:first-child) {
      border-left: 1px solid #e5e5e5;
    }
  }
}
