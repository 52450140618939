
.dashboard-refresh {        
    position: absolute;
    top: 0;
    left: 276px;
    font-size: 12px;
    
     }

// .tbgv1>button{
//     padding-left: 13px;
//     padding-right: 13px;
//     border-color: rgba(74, 77, 89, 1);
//     border-width: 2px;
//     color: rgba(74, 77, 89, 1);
//     font-weight: 600;
//     border-radius: 8px;
// }

// .tbgv1{
//     & > button.Mui-selected{
//         background: rgba(0, 185, 118, 1);
//         color:#fff;

//         &:hover{
//         background: rgba(0, 185, 118, 0.7);
//         }
//     }
// }


.FormControl .MuiSelect-select{
    padding: 10px 14px;
    font-size: 14px;
    background: #fff;
}

.cardV2{
  border-radius: 16px;
  background:  #FFF;
  box-shadow: 0px 4px 24px 0px rgba(188, 188, 188, 0.15);
}