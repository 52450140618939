.transparent-btn-md {
  background-color: transparent;
  border: none;
  padding: 12px 18px;
  position: relative;
  font-size: 1rem;
  color: #4a4d59;
  cursor: pointer;
}

.transparent-btn-md.pl-0 {
  padding-left: 0;
}

button.OD-ToggleBtn {
  border-radius: 16px;
  border: 1px solid #ffefe4;
  background: #fff;
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: #ffefe4;
  }

  &.selected {
    background-color: #cd4d4b;
    color: white;
  }
}

.OrderDetails {
  font-family: "Poppins", sans-serif;
}

.OrderDetails:focus-visible {
  outline: none;
}
